<template>
  <div>
    {{ stockDetails }}
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
export default {
  name: "balance",
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("balance", ["stockDetails"])
  },
  methods: {
    ...mapActions("balance", ["getStockDetails"])
  },
  watch: {
    user: {
      handler: function(user) {
        if (user) {
          this.getStockDetails(user.locationId)
        }
      },
      immediate: true
    }
  }
}
</script>
